import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PageLayout from "../components/page-layout";

const NotFound = () => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        siteDescription
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
          alternativeText
        }
        missingPageTitle
        missingPageDescription {
          __typename
          data {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

    const page = {
     title: strapiGlobal.missingPageTitle,
     description: strapiGlobal.missingPageDescription,
     shareImage: strapiGlobal.shareImage,
     url: '404'
    }

    const seo = {
        metaTitle: page.title,
        metaDescription: page.description,
        shareImage: page.cover,
    }

    return <PageLayout seo={seo} page={page}></PageLayout>
}

export default NotFound
